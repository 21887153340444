var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleCreate } },
        [_vm._v("创建调拨价方案")]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "15px" },
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                sortable: item.sort,
                width: "",
                align: "center",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "关联商品"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleLinkGoods(row)
                                    },
                                  },
                                },
                                [_vm._v(" 编辑 ")]
                              ),
                            ])
                          : item.label == "关联业务员"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "text-decoration": "underline",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleLinkStaff(row)
                                    },
                                  },
                                },
                                [_vm._v(" 编辑 ")]
                              ),
                            ])
                          : item.label == "有效时间"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(row.start_time_text) +
                                  "至" +
                                  _vm._s(row.end_time_text) +
                                  " "
                              ),
                            ])
                          : _c("div", [_vm._v(_vm._s(row[item.prop]))]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
          _c("el-table-column", {
            attrs: {
              prop: "",
              align: "center",
              label: "操作",
              fixed: "right",
              "min-width": "160px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleEdit($index, row)
                          },
                        },
                      },
                      [_vm._v(" 修改 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.handleDelete($index, row)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("edit", { ref: "edit", on: { refresh: _vm.fetchData } }),
      _c("link-goods", {
        ref: "linkGoods",
        attrs: { "import-type": 16, diaobojia: true },
        on: { refrsh: _vm.fetchData },
      }),
      _c("set-staff", {
        ref: "setStaff",
        attrs: { id: _vm.staffId },
        on: { refrsh: _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }