var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.showDialog,
        width: "500px",
        top: "15vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "padding-right": "10px" },
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "100px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "方案名称", prop: "", required: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.form.scheme_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "scheme_name", $$v)
                      },
                      expression: "form.scheme_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "有效时间", prop: "" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.time,
                      callback: function ($$v) {
                        _vm.time = $$v
                      },
                      expression: "time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优先级", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.form.is_close,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_close", $$v)
                        },
                        expression: "form.is_close",
                      },
                    },
                    [_vm._v("正常")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.form.is_close,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "is_close", $$v)
                        },
                        expression: "form.is_close",
                      },
                    },
                    [_vm._v("禁用")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "15px", "text-align": "right" } },
            [
              _vm.type == 1
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v(" 添加 ")]
                  )
                : _vm._e(),
              _vm.type == 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleEdit },
                    },
                    [_vm._v(" 修改 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }