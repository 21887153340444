<template>
  <!-- NAME[epic=基础] 调拨价方案 -->
  <div class="orderTest-container">
    <el-button type="primary" @click="handleCreate">创建调拨价方案</el-button>
    <el-table v-loading="loading" stripe :data="list" style="margin-top: 15px">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :sortable="item.sort"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '关联商品'">
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="handleLinkGoods(row)"
            >
              编辑
            </span>
          </div>
          <div v-else-if="item.label == '关联业务员'">
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="handleLinkStaff(row)"
            >
              编辑
            </span>
          </div>
          <div v-else-if="item.label == '有效时间'">
            {{ row.start_time_text }}至{{ row.end_time_text }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <el-button
            type="text"
            @click.native.prevent="handleEdit($index, row)"
          >
            修改
          </el-button>
          <!-- <el-button
            type="text"
            @click.native.prevent="handleCopy($index, row)"
          >
            复制
          </el-button> -->
          <el-button
            type="text"
            @click.native.prevent="handleDelete($index, row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <edit ref="edit" @refresh="fetchData"></edit>
    <link-goods
      ref="linkGoods"
      :import-type="16"
      :diaobojia="true"
      @refrsh="fetchData"
    ></link-goods>
    <set-staff :id="staffId" ref="setStaff" @refrsh="fetchData"></set-staff>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getList, deletePlan, createPlan } from '@/api/transfersPlan'
  import Edit from './components/edit'
  import LinkGoods from '@/views/project/sale/control/setPrice/components/linkGoods/index.vue'
  import SetStaff from '@//baseComponents/setStaff/index.vue'
  export default {
    name: 'TransfersPlan',
    components: { Edit, LinkGoods, SetStaff },
    data() {
      return {
        form: {
          pageSize: 10,
          pageNo: 1,
          is_close: 0,
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        loading: false,
        list: [],
        staffId: null,
        checkList: [
          '调拨价方案名称',
          '有效时间',
          '状态',
          '创建时间',
          '优先级',
          '关联商品',
          '关联业务员',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '调拨价方案名称',
            prop: 'scheme_name',
            width: '',
          },
          {
            order: 2,
            label: '有效时间',
            prop: '',
            width: '260',
          },
          {
            order: 3,
            label: '状态',
            prop: 'is_close_text',
            width: '',
          },
          {
            order: 4,
            label: '创建时间',
            prop: 'create_at',
            width: '140',
          },
          {
            order: 5,
            label: '优先级',
            prop: 'sort',
            width: '',
            sort: true,
          },
          {
            order: 6,
            label: '关联商品',
            prop: '',
            width: '',
          },
          {
            order: 7,
            label: '关联业务员',
            prop: '',
            width: '',
          },
          {
            order: 8,
            label: '备注',
            prop: 'remark',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await getList(this.form)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      handleCreate() {
        this.$refs.edit.title = '创建调拨价方案'
        this.$refs.edit.type = 1 // type 1添加 2编辑
        this.$refs.edit.showDialog = true
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleEdit(index, row) {
        this.$refs.edit.title = '修改调拨价方案'
        this.$refs.edit.type = 2 // type 1添加 2编辑
        this.$refs.edit.id = row.id
        this.$refs.edit.showDialog = true
      },
      handleCopy(index, row) {
        let data = {
          ...row,
          ...{ start_time: row.start_time_text, end_time: row.end_time_text },
        }
        createPlan(data).then((res) => {
          this.$message.success('复制成功')
          this.fetchData()
        })
      },
      handleDelete(index, row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deletePlan({ id: row.id }).then((res) => {
              this.$message.success('删除成功')
              this.fetchData()
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      // TODO 关联商品
      handleLinkGoods(row) {
        this.$refs.linkGoods.id = row.id
        this.$refs.linkGoods.defaultApi = 1
        this.$refs.linkGoods.showDialog = true
      },
      handleLinkStaff(row) {
        this.$refs.setStaff.isTrans = 1
        this.staffId = row.id
        this.$refs.setStaff.showDialog = true
      },
    },
  }
</script>
<style lang="scss" scoped></style>
