<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="500px"
    top="15vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="100px"
        label-suffix=":"
        style="padding-right: 10px"
      >
        <el-form-item label="方案名称" prop="" required>
          <el-input v-model="form.scheme_name" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="有效时间" prop="">
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="优先级" prop="">
          <el-input v-model="form.sort" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="">
          <el-input v-model="form.remark" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="">
          <!-- <el-input v-model="form.remark" style="width: 100%"></el-input> -->
          <el-radio v-model="form.is_close" :label="0">正常</el-radio>
          <el-radio v-model="form.is_close" :label="1">禁用</el-radio>
        </el-form-item>
      </el-form>
      <div style="margin-top: 15px; text-align: right">
        <el-button v-if="type == 1" type="primary" @click="handleSubmit">
          添加
        </el-button>
        <el-button v-if="type == 2" type="primary" @click="handleEdit">
          修改
        </el-button>
        <el-button @click="close">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { createPlan, planDetail, editPlan } from '@/api/transfersPlan'
  export default {
    name: '',
    components: {},
    data() {
      return {
        showDialog: false,
        type: 1, // type 1添加 2编辑
        id: null,
        title: '标题',
        form: {
          scheme_name: '', //方案名称
          start_time: '', //开始时间
          end_time: '', //结束时间
          sort: '', //优先级0~100 默认为0
          remark: '', //备注
          is_close: '', //状态 1禁用0正常 默认为0
        },
        time: [],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.time = []
          this.id = null
          this.form = {
            scheme_name: '', //方案名称
            start_time: '', //开始时间
            end_time: '', //结束时间
            sort: '', //优先级0~100 默认为0
            remark: '', //备注
            is_close: '', //状态 1禁用0正常 默认为0
          }
        } else {
          if (this.type == 2) {
            this.getDetail()
          }
        }
      },
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      handleSubmit() {
        createPlan(this.form).then((res) => {
          this.$message.success('添加成功')
          this.$emit('refresh')
          this.close()
        })
        // this.$refs.form.validate((val) => {
        //   if (val) {
        //     console.log('form', this.form)
        //   }
        // })
      },
      close() {
        this.showDialog = false
      },
      getDetail() {
        planDetail({ id: this.id }).then((res) => {
          console.log('data', res.data)
          this.form = res.data
          this.form.start_time = res.data.start_time_text
          this.form.end_time = res.data.end_time_text
          this.time = [this.form.start_time, this.form.end_time]
        })
      },
      handleEdit() {
        editPlan({ ...this.form, ...{ id: this.id } }).then((res) => {
          this.$message.success('修改成功')
          this.$emit('refresh')
          this.close()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
