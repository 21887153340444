import request from '@/utils/request'
/**
 *
 * @param {调拨方案价列表}} data
 */
export function getList(data) {
  return request({
    url: '/saleAdmin/allot-scheme/index',
    method: 'post',
    data,
  })
}
/**
 * @创建方案
 */
export function createPlan(data) {
  return request({
    url: '/saleAdmin/allot-scheme/create',
    method: 'post',
    data,
  })
}
/**
 * @方案详情
 */
export function planDetail(data) {
  return request({
    url: '/saleAdmin/allot-scheme/view',
    method: 'post',
    data,
  })
}

/**
 *
 * @param {修改方案} data
 */
export function editPlan(data) {
  return request({
    url: '/saleAdmin/allot-scheme/update',
    method: 'post',
    data,
  })
}
/**
 * @删除方案
 */
export function deletePlan(data) {
  return request({
    url: '/saleAdmin/allot-scheme/delete',
    method: 'post',
    data,
  })
}
